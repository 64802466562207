import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Amplify, { Storage, API, graphqlOperation } from "aws-amplify"
import "./index.scss"
import BackButton from "../images/backButton.png"

const ImageViewer = props => {
  const [image, setImage] = useState(null)
  useEffect(() => {
    fetchImage()
  }, [])
  async function fetchImage() {
    try {
      const image = await Storage.get("thumbnail" + props.image)
      setImage(image)
    } catch {
      const image = await Storage.get(props.image)
      setImage(image)
    }
  }
  return image && <img width="70" height="70" src={image}></img>
}

const ByArsPage = props => {
  useEffect(() => {
    var markers = []
    var markerPositions = []
    props.data.project.listProjects.items.map(project => {
      if (project.location !== null) {
        markers.push({
          position: [project.location.lat, project.location.lon],
          text: project.title,
          id: project.id,
          onHubs: project.onHubs,
          featured: project.featured,
        })
        markerPositions.push([project.location.lat, project.location.lon])
      }
    })
    props.addMarker(markers)
    // props.flyToBounds(markerPositions)
    return () => {
      props.flyToBounds([])
      props.addMarker([])
    }
  }, [])

  return (
    <section className="homepage">
      <SEO title="Ars-VR" />
      <div className="padded">
        <Link to="/">
          <img className="back-button" src={BackButton}></img>
        </Link>
        <h1>Ars-VR</h1>
        <p>Ars Electronica</p>
      </div>
      <div className="featured">
        {props.data.project.listProjects.items.map(project => (
          <div key={project.id} className="project">
            <Link to={"/project/" + project.id}>
              <ImageViewer image={project.image} />
              <div className="title-and-description">
                <div className="title">{project.title}</div>
                <div className="subtitle">{project.subtitle}</div>
                <div className="description">
                  {project.description.substring(0, 55) + "..."}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      {/* <h2>
        <Link to="/beammeup">Beam me up!</Link>
      </h2>
      <h2>
        <Link to="/page2">Page 2</Link>
      </h2> */}
      {/* <div className="padded"></div>
      <Link to="/partner/submit">Submit a project</Link> <br /> */}
    </section>
  )
}
export const query = graphql`
  {
    project {
      listProjects(
        filter: {
          title: { contains: "Ars Electronica Linz" }
          approved: { eq: true }
        }
        limit: 1000
      ) {
        items {
          id
          title
          onHubs
          featured
          subtitle
          description
          image
          location {
            lat
            lon
          }
        }
      }
    }
  }
`

export default ByArsPage
